import * as XLSX from "xlsx";

export const exportReferenceAnalysisToExcel = (data, fileName) => {
  const flattenedData = data.map((item) => ({
    Received: item?.timestamp || "",
    Journal: item.journal_name || "",
    "Manuscript ID": item.manuscript_id || "",
    "Manuscript title": item.title || "",
    Status: item?.raw_match_status || "",
  }));

  const worksheet = XLSX.utils.json_to_sheet(flattenedData);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "ReferenceAnalysis");

  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
