import React from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBTypography,
} from "mdb-react-ui-kit";
import { NoDataIcon } from "../NoDataIcon/NoDataIcon";

const NoDataAvailable = () => (
  <div className="text-center my-5">
    <NoDataIcon />
    <p className="mt-2">No data available</p>
  </div>
);

const DataTable = ({
  columns,
  rows,
  areRowsPreMapped = false,
  title = null,
}) => {
  const hasData = !!rows?.length;

  return (
    <>
      {title && (
        <MDBTypography tag="h3" className="mb-5 text-center text-muted">
          {title}
        </MDBTypography>
      )}
      <MDBTable responsive className="px-2" style={{ cursor: "pointer" }} hover>
        <MDBTableHead light>
          <tr>
            {columns.map((column, idx) => (
              <th key={idx} style={{ minWidth: column.width }}>
                {column.label}
              </th>
            ))}
          </tr>
        </MDBTableHead>
        {hasData && (
          <MDBTableBody>
            {areRowsPreMapped
              ? rows
              : rows.map((row, idx) => (
                  <tr
                    key={idx}
                    onClick={() => row?.clickEvent?.(row)}
                    className="row-hover"
                  >
                    {columns.map((column, colIdx) => (
                      <td key={colIdx} style={{ minWidth: column.width }}>
                        {column.format
                          ? column.format(row[column.field])
                          : row[column.field]}
                      </td>
                    ))}
                  </tr>
                ))}
          </MDBTableBody>
        )}
      </MDBTable>
      {!hasData && <NoDataAvailable />}
    </>
  );
};

export default DataTable;