import React from "react";
import { withFormik } from "formik";
import "./Login.css";
import LoginForm from "../../components/LoginForm";
import { authorize, showErrorToast } from "../../helpers";

const Login = (props) => {
  return (
    <div className="container form_wrapper d-flex justify-content-center">
      <LoginForm {...props} />
    </div>
  );
};

const mapPropsToValues = () => ({
  username: "",
  password: "",
});

function isAnyToolAvailable(tools) {
  return tools ? Object.values(tools).some((value) => value === true) : false;
}

const handleSubmit = async (value, { props }) => {
  try {
    const auth = await authorize({
      ...value,
      username: value.username.trim().toLowerCase(),
    });
    const tools = await props.login(auth);

    if (!isAnyToolAvailable(tools)) {
      showErrorToast("No permitted services");
      return;
    }

    props.navigate("/screened-manuscripts");
  } catch (error) {
    console.error(error);
    if (error.response?.status === 401) {
      showErrorToast("Not authorized");
    } else {
      showErrorToast("Error, something went wrong");
    }
  }
};

export default withFormik({ handleSubmit, mapPropsToValues })(Login);
