import Jsontableify from "jsontableify";
import Parser from "html-react-parser";
import { ModalWrapper } from "../ModalWrapper";
import "./DuplicateSubmissionReportModal.css";
import { MDBRow, MDBModalFooter, MDBTooltip } from "mdb-react-ui-kit";
import { capitalizeFirstLetter, convertISOToReadableDate } from "../../helpers";

const getStatusSummary = (item) => {
  const results = [];
  const { submission_id, submission_status } = item;

  if (submission_status === "duplicate confirmed") {
    results.push("duplicate confirmed");
  } else if (submission_status === "suspect paper mill") {
    results.push("suspect paper mill reported");
  } else if (submission_status === "false positive") {
    results.push("reported false positive");
  }
  if (!submission_id) {
    results.push("submission removed");
  }

  return capitalizeFirstLetter(results.join("; "));
};

const formatReport = (data) => {
  const formatManuscript = (item) => {
    const {
      submitted,
      manuscript_id,
      journal,
      publisher_name,
      title,
      feedback_email,
    } = item;
    const submissionStatus = getStatusSummary(item);

    return {
      Date: convertISOToReadableDate(submitted, "DD MMM YYYY HH:mm"),
      Publisher: feedback_email
        ? `<a href="mailto:${feedback_email}?subject=${manuscript_id}">${publisher_name}</a>`
        : publisher_name,
      Journal: journal,
      ManuscriptID: manuscript_id,
      Title: title,
      ...(submissionStatus && { "Submission status": submissionStatus }),
    };
  };

  const { matches, trigger } = data;
  const reportData = formatManuscript(trigger);
  const formattedMatches = matches?.map(formatManuscript);

  return {
    ...reportData,
    "Matched Manuscripts": formattedMatches?.length ? formattedMatches : null,
  };
};

const renderModalContent = (data) => {
  if (!data) return null;

  const reportData = formatReport(data);
  const jt = new Jsontableify();
  const content = jt.toHtml(reportData).html;
  return <div className="modal_table_wrapper">{Parser(content)}</div>;
};

const DuplicateSubmissionReportModal = ({ contentData, onClose }) => {
  const isOpen = !!contentData;

  return (
    <ModalWrapper
      title={`Duplicate submissions match report`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <MDBRow>{renderModalContent(contentData)}</MDBRow>
      <MDBModalFooter className="justify-content-center">
        <MDBTooltip
          tag="span"
          title={
            <ol className="text-start">
              Add comment: Will first copy the watchlist entry after which it
              appears under the admin feature and can be provided with its own
              comment via export/import.
              <li>Hit "Add comment".</li>
              <li>Switch to "admin".</li>
              <li>"Export" the list.</li>
              <li>Add the comment in Excel.</li>
              <li>"Import" the list.</li>
            </ol>
          }
        ></MDBTooltip>
      </MDBModalFooter>
    </ModalWrapper>
  );
};

export { DuplicateSubmissionReportModal };
