import React from "react";
import {
  ModalWrapper,
  ReviewModalGeneralInfoCard,
} from "../../../components/ModalWrapper";
import ReferenceAnalysisDataReview from "./ReferenceAnalysisDataReview";

export const ReferenceAnalysisReviewModal = ({ onClose, contentData }) => {
  const isOpen = !!contentData;

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalWrapper
      title={"Reference analysis signal report"}
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <ReviewModalGeneralInfoCard data={contentData} />
      <ReferenceAnalysisDataReview data={contentData} />
    </ModalWrapper>
  );
};
