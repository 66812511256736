export const getDataTableColumns = (filterComponent) => [
  {
    label: "Received",
    field: "timestamp",
  },
  {
    label: "Journal",
    field: "journal_name",
    width: 120,
  },
  {
    label: "Manuscript\u00A0ID",
    field: "manuscript_id",
  },
  {
    label: "Manuscript title",
    field: "title",
  },
  {
    label: filterComponent,
    field: "match_status",
    width: 220,
  },
];
