import { MDBListGroup, MDBTypography } from "mdb-react-ui-kit";

export const renderGroupItem = (value, title) => {
  if (!value) return null;

  return (
    <MDBTypography>
      <strong>{title}:</strong> {value}
    </MDBTypography>
  );
};

export const renderFeetOfClayDetails = (item, index) => (
  <MDBListGroup flush key={index}>
    {renderGroupItem(item?.ratio?.toFixed(2), "Ratio")}
    {item?.details?.map((detail, detailIndex) => (
      <div key={detailIndex}>
        <hr className="hr" />
        <div>
          <strong>DOI:</strong> {detail?.doi}
        </div>
        <div>
          <strong>Comments:</strong> {detail?.comments}
        </div>
      </div>
    ))}
  </MDBListGroup>
);
