export const token = `${process.env.REACT_APP_TOKEN}`;
export const wstoken = `${process.env.REACT_APP_WSTOKEN}`;
export const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}`;

export const ITEMS_PER_PAGE = 25;
export const errorMessage = "Error, something went wrong";
export const successMessage = "Action completed successfully";

export const notificationsTime = 3000;

export const defaultColor = "#4285F4";

export const watchlistSignals = [
  { label: 'Fake affiliation names', value: 'watchlistFakeAffiliationNames' },
  { label: 'Fake names or e-mail addresses', value: 'watchlistFakeNamesOrEmailAddresses' },
  { label: 'Fake e-mail domains', value: 'watchlistFakeEmailDomains' },
  { label: 'Disposable e-mail domains', value: 'watchlistDisposableEmailDomains' },
  { label: 'Bad actors', value: 'watchlistBadActors' },
  { label: 'Suspect articles', value: 'watchlistSuspectArticles' },
  { label: 'Manuscripts offered for sale', value: 'watchlistManuscriptsOfferedForSale ' },
  { label: 'Metadata suspects', value: 'watchlistMetadataSuspects' },
  { label: 'GenAI response', value: 'watchlistChatGPTOutput' }
];

export const referenceSignals = [
  { label: 'DOI analysis', value: 'doiResolutions' },
  { label: 'Retraction Watch', value: 'retractionWatch' },
];
