import {
  ModalWrapper,
  ReviewModalGeneralInfoCard,
} from "../../../components/ModalWrapper";
import WatchlistDataReview from "./WatchlistDataReview";

export const WatchlistReviewModal = ({ contentData, onClose }) => {
  const isOpen = !!contentData;

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalWrapper
      title="Watchlist hit(s) report"
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <ReviewModalGeneralInfoCard data={contentData} />
      <WatchlistDataReview data={contentData} />
    </ModalWrapper>
  );
};
