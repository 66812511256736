import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";

export const renderListItems = (items, renderItem) => {
  return (
    <MDBListGroup>
      {items?.map((item, index) => (
        <MDBListGroupItem key={index} className="d-flex align-items-start">
          {renderItem(item)}
        </MDBListGroupItem>
      ))}
    </MDBListGroup>
  );
};

export const renderDoiItem = (item) => (
  <>
    <div>
      <strong>DOI:</strong> {item?.DOI}
      <br />
      <strong>Reference:</strong> {item?.title}
    </div>
  </>
);
