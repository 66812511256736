import { MDBDropdown, MDBDropdownMenu, MDBIcon } from 'mdb-react-ui-kit';
import { DropdownToggle, DropdownHeader } from "./";

export const FilterIconHeader = ({ label, dropdownTitle, children, options }) => {
  return (
    <div className="d-flex align-items-center">
      <span>{label}</span>
      <MDBDropdown
        options={options}
      >
        <DropdownToggle tag='a' color='none' className='m-2' style={{ color: '#3b5998' }}>
          <MDBIcon icon='filter' size='md' />
        </DropdownToggle>
        <MDBDropdownMenu appendToBody style={{ width: '310px' }}>
          {dropdownTitle && <DropdownHeader className="px-4 mt-1">{dropdownTitle}</DropdownHeader>}
          {children}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  )
};
