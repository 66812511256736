import { useState, useCallback } from 'react';
import { MDBBtn, MDBInputGroup } from 'mdb-react-ui-kit';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const SearchInput = ({ placeholder = '', onSubmit, debounceDelay = 500 }) => {
  const [value, setValue] = useState('');

  const debouncedSubmit = useCallback(
    debounce((val) => onSubmit(val), debounceDelay),
    [onSubmit, debounceDelay]
  );

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    debouncedSubmit(newValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSubmit(value);
    }
  };

  return (
    <MDBInputGroup className="p-1">
      <input
        className="form-control"
        placeholder={placeholder}
        type="text"
        onChange={handleChange}
        value={value}
        onKeyDown={handleKeyPress}
      />
      <MDBBtn onClick={() => onSubmit(value)}>Search</MDBBtn>
    </MDBInputGroup>
  );
};

export default SearchInput;