
const colorizeStatusText = (text) => {
  let coloredText = text;
  if (/duplicate confirmed/gi.test(text) || /suspect paper mill/gi.test(text)) {
      coloredText = `<span style="color: red;">${text}</span>`;
  }
  else if (/reported false positive/gi.test(text) || /submission removed/gi.test(text)) {
      coloredText = `<span style="color: green;">${text}</span>`;
  }
  return coloredText;
};

const ColorizeStatusText = ({ statusText }) => {
  const coloredText = colorizeStatusText(statusText);
  return <div dangerouslySetInnerHTML={{ __html: coloredText }} />;
};

export { ColorizeStatusText };