import { useState, useEffect } from "react";
import { columns } from "../columns";
import DataTable from "../../../components/table/DataTable";
import useDuplicateSubmissions from "../hooks/useDuplicateSubmissions";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import Pagination from "../../../components/Pagination/Pagination";
import NoContentMessage from "../../../components/NoContentMessage/NoContentMessage";
import { DuplicateSubmissionReportModal } from "../../../components";
import { AmbientScreeningReviewModal } from "../../screened-manuscripts/components/AmbientScreeningReviewModal";
import { ColorizeStatusText } from "../components/ColorizeStatusText";
import { ApplyAction } from "../components/ApplyAction";
import { ReportActionButtons, SECTION_KEYS } from "../../screened-manuscripts/components/ReportActionButtons";
import { convertISOToReadableDate, getAmbientScreeningSubmissionById } from "../../../helpers";

const ManuscriptsInboxTab = ({ type, isActiveTab }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedAmbientScreening, setSelectedAmbientScreening] = useState({
    index: null,
    visibleSection: SECTION_KEYS.ALL,
  });
  const [modalContentData, setModalContentData] = useState(null);

  const {
    data,
    fetchData,
    setData,
    setTriggerOrMatchStatus,
    isLoading,
    setIsLoading,
    error,
    pageCount,
    currentPage,
    setCurrentPage,
  } = useDuplicateSubmissions({ type, isActiveTab });

  const updateStatusWithRollbackAndReload = async (index, status) => {
    const newData = [...data];
    const previousElement = { ...newData[index] };
    newData.splice(index, 1);
    setData(newData);

    const { match_id, persistent_id } = previousElement;
    try {
      await setTriggerOrMatchStatus(match_id, persistent_id, status);
      await fetchData();
    } catch (error) {
      newData.splice(index, 0, previousElement);
      setData(newData);
    }
  };

  const onActionSelect = (index, status) => {
    updateStatusWithRollbackAndReload(index, status);
  };

  const handleIconClick = (index, buttonKey) => {
    setSelectedAmbientScreening({ index, visibleSection: buttonKey });
  };

  const handleRowClick = (index) => {
    if (index === null) {
      return;
    }
    setSelectedRowIndex(index);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleCloseModal = () => {
    setSelectedAmbientScreening({
      index: null,
      visibleSection: SECTION_KEYS.ALL,
    });
  };

  const dataWithActions = data?.map((element, index) => ({
    ...element,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    match_status: <ColorizeStatusText statusText={element.match_status} />,
    action: (
      <div className="d-flex">
        <ApplyAction
          index={index}
          status={data[index].submission_status}
          selectAction={onActionSelect}
        />
      </div>
    ),
    signals: (
      <ReportActionButtons
        signals={element.signals}
        onIconClick={(buttonKey) => handleIconClick(index, buttonKey)}
      />
    ),
    clickEvent: () => handleRowClick(index),
  }));

  const fetchAmbientScreeningDataIfNeeded = async (index) => {
    const { persistent_id } = data[index];
    if (persistent_id) {
      setIsLoading(true);
      try {
        const { data: fetchedData } = await getAmbientScreeningSubmissionById(persistent_id);
        setModalContentData(fetchedData);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedAmbientScreening.index !== null) {
      fetchAmbientScreeningDataIfNeeded(selectedAmbientScreening.index);
    } else {
      setModalContentData(null);
    }
  }, [selectedAmbientScreening.index]);

  return (
    <SpinnerOverlay active={isLoading} centerOnScreen={true}>
      <DuplicateSubmissionReportModal
        contentData={data[selectedRowIndex]}
        onClose={() => setSelectedRowIndex(null)}
      />
      {selectedAmbientScreening.index !== null && modalContentData && (
        <AmbientScreeningReviewModal
          contentData={modalContentData}
          visibleSection={selectedAmbientScreening.visibleSection}
          onClose={handleCloseModal}
        />
      )}
      {error && <ErrorMessage />}
      {!error && !isLoading && data?.length === 0 && <NoContentMessage />}
      {!error && data?.length > 0 && (
        <>
          <DataTable columns={columns} rows={dataWithActions} />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </SpinnerOverlay>
  );
};

export default ManuscriptsInboxTab;
