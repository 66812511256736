import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { InfoTooltip } from "../../../components";

export const renderField = (label, value) => {
  return value ? (
    <p>
      <strong>{label}:</strong> {value}
    </p>
  ) : null;
};

export const renderAnyHitDetails = (item, index, array) => (
  <div key={index}>
    {renderField("Value", item?.value)}
    {renderField("Provided by", item?.providedby)}
    {renderField("Timestamp", item?.timestamp)}
    {renderField("Comment", item?.comment)}
    {index < array.length - 1 && <hr className="hr" />}
  </div>
);

export const renderBadActorDetails = (item, index, array) => (
  <div key={index}>
    {renderField("Name", item?.value?.name)}
    {renderField("Institution", item?.value?.institution)}
    {renderField("Email", item?.value?.email)}
    {renderField("Phone", item?.value?.phone)}
    {renderField("ORCID", item?.value?.ORCID)}
    {renderField("IP address", item?.sourceValue?.ip)}
    {renderField("Breach type", item?.breach)}
    {renderField("Provided by", item?.providedby)}
    {renderField("Timestamp", item?.timestamp)}
    {renderField("Comment", item?.comment)}
    {index < array.length - 1 && <hr className="hr" />}
  </div>
);

export const renderCardSection = (
  items,
  title,
  renderFunction,
  tooltipText = null
) => {
  if (items.length === 0) return null;

  return (
    <MDBCard className="mb-4">
      <MDBCardHeader>
        <MDBCardTitle>
          {title} {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
        </MDBCardTitle>
      </MDBCardHeader>
      <MDBCardBody>
        {items.map((item, index, array) => renderFunction(item, index, array))}
      </MDBCardBody>
    </MDBCard>
  );
};
