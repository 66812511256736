import React from "react";
import {
  MDBCard,
  MDBCardText,
  MDBCardTitle,
  MDBCardBody,
  MDBCardHeader,
} from "mdb-react-ui-kit";

const CountCard = ({ title, subtitle, count, onClick }) => (
  <MDBCard
    className="mx-5 py-2 w-50"
    style={{ cursor: onClick && "pointer" }}
    onClick={onClick ?? null}
  >
    <MDBCardBody className="d-flex flex-column align-items-center">
      <MDBCardHeader>
        <MDBCardTitle className="text-uppercase my-1">{title}</MDBCardTitle>
      </MDBCardHeader>
      <MDBCardText className="mt-1 mb-0">
        <strong>{subtitle}:</strong>
      </MDBCardText>
      <span className="text-muted fs-1 pointer">{count}</span>
    </MDBCardBody>
  </MDBCard>
);

export { CountCard };
