import React from "react";
import Layout from "./Layout";
import Footer from "./components/Footer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  return (
    <>
      <ToastContainer />
      <Layout />
      <Footer />
    </>
  );
};


export default App;