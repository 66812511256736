import React from 'react';
import ReactPaginate from 'react-paginate';
import "./Pagination.css";


const Pagination = ({ handlePageClick, pageCount, currentPage, visible = true }) => {
  const paginationClassName = visible ? "pagination" : "pagination invisible";


  return (
    <>
      {pageCount > 1
        ?
        <ReactPaginate
          previousLabel={"previous"}
          previousClassName={currentPage + 1 > 1 ? "previous" : "invisible"}
          nextClassName={currentPage + 1 < pageCount ? "next" : "invisible"}
          nextLabel={"next"}
          breakLabel={"..."}
          forcePage={currentPage}
          pageClassName={"page"}
          pageCount={pageCount}
          marginPagesDisplayed={3}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={paginationClassName}
          activeClassName={"active"}
        />
        :
        null
      }
    </>
  );
};


export default Pagination;
