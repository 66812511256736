import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import {camelCaseToSentenceCase} from '../../../helpers'

export const renderListItems = (items, renderItem) => {
  return (
    <MDBListGroup>
      {items?.map((item, index) => (
        <MDBListGroupItem key={index} className="d-flex align-items-start">
          {renderItem(item)}
        </MDBListGroupItem>
      ))}
    </MDBListGroup>
  );
};

export const renderWordFilePropertyItem = (item) => {
  const { message, details } = item;
  return (
    <div className="p-2">
      {message}<br />
      {details && (
        <ul className="mt-2 list-unstyled">
          {Object.entries(details).map(([key, value]) => (
            <li className="ms-4" key={key}>
              <strong>{camelCaseToSentenceCase(key)}:</strong> {value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};