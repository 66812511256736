import { errorMessage } from "../constants";

export const handleErrors = error => {
    if (error.response?.status === 401) {
        const event = new Event('force-logout');
        window.dispatchEvent(event);
    } else if (error.response) {
        return error.response.data.message;
    }
    return errorMessage;
};
