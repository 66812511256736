import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "./handleErrors";

export const authorize = (credentials) => {
  return axiosInstance.post("authorize", credentials);
};

export const getWsToken = async () => {
  return axiosInstance.get("/papermill/wstoken").catch(handleErrors);
};

export const getUser = async () => {
  return axiosInstance.get("/user").catch(handleErrors);
};
