import { ModalWrapper, ReviewModalGeneralInfoCard } from "../../../components/ModalWrapper";
import PubpeerDataReview from "./PubpeerDataReview";

const PubpeerReviewModal = ({ contentData, onClose }) => {
  const isOpen = !!contentData;

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalWrapper
      title={"Pubpeer"}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ReviewModalGeneralInfoCard data={contentData} />
      <PubpeerDataReview data={contentData} />
    </ModalWrapper>
  );
};

export { PubpeerReviewModal };
