import { MDBCol, MDBBtn, MDBCheckbox, MDBRow } from "mdb-react-ui-kit";
import { Formik, Field, Form } from "formik";
import { simulateEscKeyPress } from "../../helpers";

export const SwitchForm = ({ handleSubmit, handleReset, initialValues, options }) => {
  const handleFormSubmit = (values) => {
    handleSubmit(values);
    simulateEscKeyPress();
  };
  
  const handleFormReset = () => {
    handleReset();
    simulateEscKeyPress();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      onReset={handleFormReset}
    >
      {() => (
        <Form className="px-4 my-3">
          {options.map((item, index) => (
            <div key={index} className="mb-2">
              <Field name={item.value}>
                {({ field, form }) => (
                  <MDBCheckbox
                    id={item.value}
                    label={item.label}
                    checked={field.value}
                    value={field.value}
                    wrapperStyle={{ paddingBottom: "0.15rem" }}
                    onChange={() => { form.setFieldValue(item.value, !field.value) }}
                  />
                )}
              </Field>
            </div>
          ))}

          <MDBRow className="mt-3 py-1">
            <MDBCol className="d-flex justify-content-center">
              <MDBBtn size="sm" type="submit" className="me-2">
                Set
              </MDBBtn>
              <MDBBtn size="sm" type="reset" className="ms-2" color="secondary">
                Reset
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </Form>
      )}
    </Formik>
  )
};
