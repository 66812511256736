import { axiosInstance } from "./axiosSetup";

export async function getClearSkiesList(startrow, maxrows) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      maxrows: maxrows,
      startrow: startrow,
    },
    path: "/reports/tools/clearskies/manuscripts",
    method: "get",
  });
}
