import {
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBCard
} from "mdb-react-ui-kit";
import parser from "html-react-parser";
import styled from "styled-components";

export const HrefWrapper = styled.span`
  a:link,
  a:visited {
    color: black;
    background-color: yellow;
    text-decoration: none;
  }

  a:hover {
    color: black;
    background-color: yellow;
    text-decoration: underline;
  }

  & mark {
    background-color: #fdfa9e !important;
  }
`;

const StyledMDBCard = styled(MDBCard)`
  background-color: #ebebeb !important;
  box-shadow: none !important;
`;

export const renderIndicationDisplay = (item, index) => {
  return (
    <MDBRow key={index} className={index > 0 ? "mt-4" : ""}>
      {item?.sentence ? (
        <>
          <MDBCol md="9">
            <StyledMDBCard className="px-3 py-4">
              {item.sentence.map((sentence, index) => (
                <div key={index}>
                  <HrefWrapper>{parser(`${sentence}`)}</HrefWrapper>
                </div>
              ))}
            </StyledMDBCard>
          </MDBCol>

          <MDBCol md="3">
            <MDBTypography style={{ color: 'grey' }} variant="h6">
              <>Indication {(index + 1).toString()}</>
            </MDBTypography>
            {item?.expectedText ? (
              <div style={{ color: '#6699cc' }}>
                <>
                  <b>Established term:</b>
                  <br />
                  &#34;{item.expectedText}&#34;
                </>
                <div className="mt-2">
                  <b>Pattern:</b>
                  <br />
                  &#34;{item.pattern}&#34;
                </div>
              </div>
            ) : null}
          </MDBCol>
        </>
      ) : (
        <div className="ps-2">None found</div>
      )}
    </MDBRow>
  );
};
