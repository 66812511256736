import { useEffect, useState, useMemo } from "react";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { useAuth } from "../../components/Auth";
import { convertISOToReadableDate, showErrorToast } from "../../helpers";
import { getDataTableColumns } from "./columns";
import { CountCard } from "../../components";
import { getWatchlistReports } from "../../helpers/watchlist";
import DataTable from "../../components/table/DataTable";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import NoContentMessage from "../../components/NoContentMessage/NoContentMessage";
import {
  extractUniqueTypes,
  formatScreeningOutcomeHits,
  formatScreeningOutcomeHitsPlainText,
  WatchlistReviewModal,
} from "./components";
import { FilterIconHeader } from "../../components/FilterIconHeader";
import { SwitchForm } from "../../components/SwitchForm";
import { watchlistSignals } from "../../constants";
import { exportWatchlistToExcel } from "./export";

const Watchlist = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const { components } = useAuth();
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [filterSelection, setFilterSelection] = useState([]);
  const [selectedDataRowIndex, setSelectedDataRowIndex] = useState(null);

  const handleRowClick = (index) => {
    setSelectedDataRowIndex(index);
  };

  const handleCloseModal = () => setSelectedDataRowIndex(null);

  const dataWithActions = filteredData?.map((element, index) => ({
    ...element,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    raw_hits: formatScreeningOutcomeHitsPlainText(element.screening_outcomes),
    hits: (
      <span
        dangerouslySetInnerHTML={{
          __html: formatScreeningOutcomeHits(element.screening_outcomes),
        }}
      />
    ),
    manuscript_id: element.manuscript_id ?? "",
    clickEvent: () => handleRowClick(index),
  }));

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data: { manuscripts = [], total = 0 } = {} } =
          await getWatchlistReports();
        setAllData(manuscripts);
        setTotalHits(total);
        const uniqueTypes = extractUniqueTypes(manuscripts);
        const initialFilter = Object.fromEntries(
          uniqueTypes.map((type) => [type, true])
        );
        setFilterSelection(initialFilter);
      } catch (error) {
        showErrorToast();
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(filterSelection).length) {
      const filteredManuscripts = allData.filter((manuscript) =>
        manuscript.screening_outcomes.some(
          (outcome) => filterSelection[outcome.type]
        )
      );
      setFilteredData(filteredManuscripts);
    }
  }, [filterSelection]);

  const options = useMemo(() => {
    return watchlistSignals.filter((signal) =>
      components.hasOwnProperty(signal.value)
    );
  }, [components]);

  const handleFilterSubmit = (values) => {
    setFilterSelection(values);
  };

  const handleFilterReset = () => {
    const resetValues = Object.fromEntries(
      Object.keys(filterSelection).map((type) => [type, true])
    );
    setFilterSelection(resetValues);
  };

  const filterComponent = (
    <FilterIconHeader label="Hits" dropdownTitle="Signal Type Selection">
      <SwitchForm
        handleSubmit={handleFilterSubmit}
        handleReset={handleFilterReset}
        initialValues={filterSelection}
        options={options}
      />
    </FilterIconHeader>
  );
  const tableColumns = getDataTableColumns(filterComponent);

  const handleExport = () => {
    exportWatchlistToExcel(dataWithActions, "Watchlist");
  };

  return (
    <SpinnerOverlay active={isLoading}>
      <MDBContainer className="my-5">
        {selectedDataRowIndex !== null && (
          <WatchlistReviewModal
            contentData={filteredData[selectedDataRowIndex]}
            onClose={handleCloseModal}
          />
        )}
        {error && <ErrorMessage />}
        {!error && !isLoading && allData?.length === 0 && <NoContentMessage />}
        {!error && !isLoading && (
          <>
            <div className="d-flex justify-content-center m-5">
              <CountCard
                count={totalHits}
                title={"WATCHLIST HITS"}
                subtitle={"Screened manuscripts with hits"}
              />
            </div>
            <div className="d-flex justify-content-end mb-3">
              <MDBBtn
                disabled={isLoading}
                color="primary"
                className="px-4"
                onClick={handleExport}
              >
                Export
              </MDBBtn>
            </div>
            <DataTable columns={tableColumns} rows={dataWithActions} />
          </>
        )}
      </MDBContainer>
    </SpinnerOverlay>
  );
};

export default Watchlist;
