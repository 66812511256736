import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarToggler,
  MDBCollapse,
  MDBBtn,
  MDBNavbarLink,
  MDBContainer,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useAuth } from "./Auth";
import styled from "styled-components";
import { defaultColor } from "../constants";

const NavbarWrapper = styled.div`
  .navbar {
    // border-top: 3px solid white;
    // background-color: ${process.env.REACT_APP_PUBLISHER_COLOR ||
    defaultColor};
  }

  .nav-item .nav-link a {
    color: rgba(255, 255, 255, 0.55) !important;
  }

  .nav-item .nav-link a:hover {
    filter: brightness(75%);
  }

  .nav-item .nav-link.active a {
    color: white !important;
  }

  .nav-item .nav-link.disabled a {
    filter: brightness(50%);
  }
`;

const Navbar = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { logout, isFeatureEnabled } = useAuth();

  const toggleCollapse = () => {
    setShow((prev) => !prev);
  };

  const navbarItems = [];

  navbarItems.push({
    label: "Screened manuscripts",
    path: "/screened-manuscripts",
  });

  if (isFeatureEnabled("dupsub")) {
    navbarItems.push({
      label: "Duplicate submissions",
      path: "/duplicate-submissions",
    });
  }

  if (isFeatureEnabled("clearskies")) {
    navbarItems.push({
      label: "ClearSkies Papermill Alarm",
      path: "/clearskies",
    });
  }

  if (isFeatureEnabled("pubpeer")) {
    navbarItems.push({
      label: "Pubpeer",
      path: "/pubpeer",
    });
  }

  if (isFeatureEnabled("referenceAnalysis")) {
    navbarItems.push({
      label: "Reference analysis",
      path: "/reference-analysis",
    });
  }

  if (isFeatureEnabled("textAnalysis")) {
    navbarItems.push({
      label: "Text analysis",
      path: "/text-analysis",
    });
  }

  if (isFeatureEnabled("metadataAnalysis")) {
    navbarItems.push({
      label: "Metadata analysis",
      path: "/metadata-analysis",
    });
  }

  if (isFeatureEnabled("watchlist")) {
    navbarItems.push({
      label: "Watchlist",
      path: "/watchlist",
    });
  }

  return (
    <NavbarWrapper>
      <MDBNavbar expand="lg" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={show}>
            <MDBNavbarNav>
              {navbarItems.map((item) => (
                <MDBNavbarItem key={item.label}>
                  <MDBNavbarLink
                    active={item.path === location.pathname}
                    className={`nav-link px-3 ${
                      item.path === location.pathname && "fw-bolder"
                    }`}
                    onClick={() => navigate(item.path)}
                  >
                    {item.label}
                  </MDBNavbarLink>
                </MDBNavbarItem>
              ))}
            </MDBNavbarNav>
          </MDBCollapse>
          <div className="d-flex">
            <MDBBtn onClick={logout} color="primary" size="md">
              Log Out
            </MDBBtn>
          </div>
        </MDBContainer>
      </MDBNavbar>
    </NavbarWrapper>
  );
};

export default Navbar;
