import React from "react";
import { MDBFooter } from 'mdb-react-ui-kit'
import styled from 'styled-components';
import { defaultColor } from "../constants";

const FooterWrapper = styled.div`
.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: ${process.env.REACT_APP_PUBLISHER_COLOR || defaultColor};
  }
}
`;

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <FooterWrapper>
            <MDBFooter className="footer text-white">
                <div className='p-3'>&#169; Copyright 2022 - {currentYear} STM Solutions</div>
            </MDBFooter>
        </FooterWrapper>
    );
};


export default Footer;

