export const columns = [
  {
    label: "Received",
    field: "timestamp",
  },
  {
    label: "Journal",
    field: "journal_name",
    width: 120,
  },
  {
    label: "Manuscript\u00A0ID",
    field: "manuscript_id",
  },
  {
    label: "Manuscript title",
    field: "title",
  },
  {
    label: "Status",
    field: "match_status",
  },
  {
    label: "Action",
    field: "action",
  },
  {
    label: "Signals",
    field: "signals",
    width: 120,
  },

];
