import { axiosInstance } from "./axiosSetup";

export async function getScreenedManuscripts(startrow, maxrows, filter) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      maxrows: maxrows,
      startrow: startrow,
      filter: filter,
    },
    path: "/reports/screened/manuscripts",
    method: "get",
  });
}

export async function getScreenedManuscriptsCount() {
  const [dupsubCount, screenedCount] = await Promise.all([
    axiosInstance.post(`hub-api/proxy`, {
      path: "/reports/dupsub/count",
      method: "get",
    }),
    axiosInstance.post(`hub-api/proxy`, {
      // path: "/reports/screened/count",
      path: "/reports/ambient/count",
      method: "get",
    }),
  ]);

  return {
    dupsubCount: dupsubCount.data?.total,
    screenedCount: screenedCount.data?.total,
  };
}
