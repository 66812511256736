import React from "react";
import ActionButton from "./ActionButton";
import styled from "styled-components";

export const SECTION_KEYS = {
  DUPSUB: "dupsub",
  WATCHLIST: "watchlist",
  CLEARSKIES: "clearskies",
  REFERENCE_ANALYSIS: "reference",
  METADATA_ANALYSIS: "metadata",
  PUBPEER: "pubpeer",
  TEXT_ANALYSIS: "text",
  ALL: "all",
};

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  max-width: 100px;
`;

// Retrieve MDB primary and secondary colors
const rootStyles = getComputedStyle(document.documentElement);
const dupSubColor = rootStyles.getPropertyValue("--mdb-primary").trim();
const watchlistColor = "#6b7280"; // Slate Gray for Watchlist
const clearSkiesColor = "#ff69b4"; // Pink for ClearSkies
const textAnalysisColor = "#800080"; // Purple for Text Analysis
const referenceAnalysisColor = rootStyles
  .getPropertyValue("--mdb-warning")
  .trim(); // Warning color for Reference Analysis
const metadataAnalysisColor = rootStyles.getPropertyValue("--mdb-info").trim(); // Info (blue) for Metadata Analysis
const pubPeerColor = "#d35400"; // Burnt Orange for PubPeer

export const ReportActionButtons = ({ signals, onIconClick }) => {
  const buttonConfigs = [
    {
      key: SECTION_KEYS.DUPSUB,
      shouldShow: signals?.includes("duplicateSubmissions"),
      title: "Match in Duplicate submissions, click for report",
      color: dupSubColor, // Primary color for Duplicate Submissions
      icon: "copy",
      onClick: onIconClick,
    },
    {
      key: SECTION_KEYS.WATCHLIST,
      shouldShow: signals?.some((signal) => signal.startsWith("watchlist")),
      title: "Watchlist match found, click for details",
      color: watchlistColor, // Slate Gray for Watchlist
      icon: "eye",
      onClick: onIconClick,
    },
    {
      key: SECTION_KEYS.CLEARSKIES,
      shouldShow: signals?.includes("clearSkiesStatus"),
      title: "ClearSkies report available, click to open",
      color: clearSkiesColor, // Pink for ClearSkies
      icon: "bell",
      onClick: onIconClick,
    },
    {
      key: SECTION_KEYS.TEXT_ANALYSIS,
      shouldShow: signals?.includes("torturedPhrases"),
      title: "Text report available, click to open",
      color: textAnalysisColor, // Purple for Text Analysis
      icon: "file-alt",
      onClick: onIconClick,
    },
    {
      key: SECTION_KEYS.REFERENCE_ANALYSIS,
      shouldShow: !!signals?.some((signal) =>
        ["retractionWatch", "doiResolutions"].includes(signal)
      ),
      title: "Suspicious references, click for report",
      color: referenceAnalysisColor, // Warning color for Reference Analysis
      icon: "exclamation-triangle",
      onClick: onIconClick,
    },
    {
      key: SECTION_KEYS.METADATA_ANALYSIS,
      shouldShow: signals?.includes("wordFileProperties"),
      title: "Metadata report available, click to open",
      color: metadataAnalysisColor, // Info (blue) for Metadata Analysis
      icon: "file-word",
      onClick: onIconClick,
    },
    {
      key: SECTION_KEYS.PUBPEER,
      shouldShow: signals?.includes("feetOfClayDetector"),
      title: "PubPeer report available, click to open",
      color: pubPeerColor, // Burnt Orange for PubPeer
      icon: "search",
      onClick: onIconClick,
    },
  ];

  const visibleButtons = buttonConfigs.filter((btn) => btn.shouldShow);
  if (visibleButtons.length === 0) {
    return null;
  }

  return (
    <ButtonContainer>
      {visibleButtons.map(({ key, onClick, ...btnProps }) => (
        <ActionButton key={key} onClick={() => onClick(key)} {...btnProps} />
      ))}
    </ButtonContainer>
  );
};
