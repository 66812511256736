import React from 'react';
import {
  ModalWrapper,
  ReviewModalGeneralInfoCard,
} from '../../../components/ModalWrapper';
import WatchlistDataReview from '../../watchlist/components/WatchlistDataReview';
import ClearskiesDataReview from '../../clearskies/components/ClearskiesDataReview';
import ReferenceAnalysisDataReview from '../../reference-analysis/components/ReferenceAnalysisDataReview';
import MetadataAnalysisDataReview from '../../MetadataAnalysis/components/MetadataAnalysisDataReview';
import PubpeerDataReview from '../../pubpeer/components/PubpeerDataReview';
import TextAnalysisDataReview from '../../text-analysis/components/TextAnalysisDataReview';
import { SECTION_KEYS } from '../../screened-manuscripts/components/ReportActionButtons';

export const AmbientScreeningReviewModal = ({ onClose, contentData, visibleSection = SECTION_KEYS.ALL }) => {
  const isOpen = !!contentData;

  const handleCloseModal = () => {
    onClose();
  };

  const shouldRender = (sectionKey) => {
    return visibleSection === SECTION_KEYS.ALL || visibleSection.includes(sectionKey);
  };

  return (
    <ModalWrapper
      title={'Ambient screening outcomes'}
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <ReviewModalGeneralInfoCard data={contentData} />

      {shouldRender(SECTION_KEYS.WATCHLIST) && (
        <WatchlistDataReview data={contentData} />
      )}

      {shouldRender(SECTION_KEYS.CLEARSKIES) && (
        <ClearskiesDataReview data={contentData} withSubHeader />
      )}

      {shouldRender(SECTION_KEYS.REFERENCE_ANALYSIS) && (
        <ReferenceAnalysisDataReview data={contentData} />
      )}

      {shouldRender(SECTION_KEYS.METADATA_ANALYSIS) && (
        <MetadataAnalysisDataReview data={contentData} withSubHeader />
      )}

      {shouldRender(SECTION_KEYS.PUBPEER) && (
        <PubpeerDataReview data={contentData} withSubHeader />
      )}

      {shouldRender(SECTION_KEYS.TEXT_ANALYSIS) && (
        <TextAnalysisDataReview data={contentData} withSubHeader />
      )}
    </ModalWrapper>
  );
};
